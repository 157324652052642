import React from "react";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import Hero from "../Components/Hero";

const ContactScreen = () => {
  return (
    <div className="main">
      <NavBar />
      <Hero
        formTitle={"Contact Us"}
        formTerms={""}
        title={
          "Contact us for inquiries, assistance. We're here to connect and support you."
        }
        btnText={"Send Message"}
      >
        We value your feedback, inquiries, and comments. Reach out to us using
        the information below. Whether you have questions about our products,
        services, or just want to say hello, we're here to listen and assist
        you. Your input is important to us, and we look forward to hearing from you.
      </Hero>
      <Footer />
    </div>
  );
};

export default ContactScreen;
