import React from "react";
import Card from "./Components/Card";
// import Button from "../Button";
// import { useNavigate } from "react-router-dom";
import Container from "../Container/Container";

import "./Services.css";

const Services = ({ data, maxLength = 8 }) => {
  // const navigateTo = useNavigate();

  return (
      <Container padding="3rem 1rem" className="services">
        <h2 className="title">Our Services</h2>
        <div className="card-wrapper">
          {data.map(
            (service, i) =>
              i < maxLength && (
                <Card
                  key={service.name + i}
                  img={service.img}
                  title={service.name}
                >
                  {service.desc}
                </Card>
              )
          )}
        </div>
        {/* <div className="btn-wrapper">
          <Button
            type="button"
            text="View All"
            onClick={() => navigateTo("/services")}
          />
        </div> */}
      </Container>
  );
};

export default Services;
