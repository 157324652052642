import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomeScreen from "./Screens/HomeScreen";
import ServicesScreen from "./Screens/Services";
import FAQScreen from "./Screens/FAQ";
import ContactScreen from "./Screens/Contact";
import ScrollToTop from "./ScrollToTop";

function App() {

  
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<HomeScreen/>}/>
      <Route path="/services" element={<ServicesScreen/>}/>
      <Route path="/faq" element={<FAQScreen/>}/>
      <Route path="/contact" element={<ContactScreen/>}/>
      <Route path="*" element={<HomeScreen/>} />
    </Routes>
      <ScrollToTop/>
  </BrowserRouter>
  );
}

export default App;
