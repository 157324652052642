import React from "react";
import "./Hero.css";

import ArrowRight from "../assets/arrow-right.png";
import Slogan1 from "../assets/slogan1.webp";
import Slogan2 from "../assets/slogan2.webp";
import Slogan3 from "../assets/slogan3.webp";
import Slogan4 from "../assets/slogan4.webp";
// import Slogan5 from "../assets/slogan5.webp";
import ContactForm from "./ContactForm";
import Section from "./Section/Section";
import Container from "./Container/Container";


const Hero = ({title, formTitle, formTerms, children, btnText}) => {
  return (
    <Section dark id="consult">
      <Container className="hero-wrapper" padding="2rem 1rem 3rem">
        <div className="text-section">
          <div className="hero-title">
            <h1>{title}</h1>
            <img src={ArrowRight} alt="" />
          </div>
          <p className="about-text">
            {children}
          </p>

          <div className="slogans">
            <img src={Slogan1} alt="" />
            <img src={Slogan2} alt="" />
            <img src={Slogan3} alt="" />
            <img src={Slogan4} alt="" />
          </div>
        </div>
        <div className="form">
          <ContactForm
            title={formTitle}
            terms={formTerms}
            btnText={btnText}
          />
        </div>
      </Container>
    </Section>
  );
};

export default Hero;
