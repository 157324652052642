import React from 'react'
import NavBar from '../Components/NavBar'
import Footer from '../Components/Footer'
import { longFAQ } from '../data/faqData'
import FAQ from '../Components/FAQ'

const FAQScreen = () => {
  return (
    <div className="main">
      <NavBar/>
      <FAQ data={longFAQ} onClickScroll/>
      <Footer/>
      
    </div>
  )
}

export default FAQScreen;