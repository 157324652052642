import React, { useState } from "react";

import { Plus } from "react-feather";

import Button from "./Button";
import { useNavigate } from "react-router-dom";

import "./FAQ.css";
import Section from "./Section/Section";
import Container from "./Container/Container";

const WEBKITBOX = "-webkit-box";
const BLOCK = "block";

const FAQ = ({ data, onClickScroll = false }) => {
  const navigateTo = useNavigate();

  const [selectedFAQ, setSelectedFAQ] = useState(0);
  const [showFullAns, setShowFullAns] = useState(
    onClickScroll ? BLOCK : WEBKITBOX
  );

  const scrollToAns = () => {
    document.getElementById("faqAns").scrollIntoView();
  };
  return (
    <Section dark padding={"3rem 0.5rem"}>
      <Container className="faq-wrapper">
        <h2 className="title">FAQ</h2>
        <div className="faq-content">
          <div className="faq-left" id="faqAns">
            <h3>{data[selectedFAQ].ques}</h3>
            <div className="divider"></div>
            <p style={{ display: showFullAns }}>{data[selectedFAQ].ans}</p>
            {!onClickScroll && showFullAns === WEBKITBOX && (
              <Button
                text={"Read More"}
                onClick={() => setShowFullAns(BLOCK)}
              />
            )}
            {!onClickScroll && showFullAns === BLOCK && (
              <Button
                text={"Show Less"}
                onClick={() => setShowFullAns(WEBKITBOX)}
              />
            )}
          </div>
          <div className="faq-right">
            {data.map(
              (faq, i) =>
                i !== selectedFAQ && (
                  <div
                    key={faq.ques + i}
                    className="box"
                    onClick={() => {
                      setSelectedFAQ(i);
                      scrollToAns();
                    }}
                  >
                    <h4>{faq.ques}</h4>
                    <Plus className="icon" />
                  </div>
                )
            )}
          </div>
        </div>
        {!onClickScroll && (
          <div className="view-all" onClick={() => navigateTo("/faq")}>
            View All <span>&gt;&gt;&gt;</span>
          </div>
        )}
      </Container>
    </Section>
  );
};

export default FAQ;
