
import TAX from "../assets/tax1.png";
import GST from "../assets/gst-icon.png";
import CARD from "../assets/pan.png";
import TRADEMARK from "../assets/tm.png";
import REGISTERED from "../assets/r.png";
import TDS from "../assets/tds1.png";
import COMPANY from "../assets/company1.png";
import DS from "../assets/ds.png";
import ADUIT from "../assets/tax-audit.png";
import ISO from "../assets/iso2.png";
import NGO from "../assets/ngo1.png";
import FORM from "../assets/15form.png";

export const servicesData = [
  {
    name: "Income Tax Return",
    img: TAX,
    desc: "Filing ITR is crucial to meet legal obligations, claim refunds, and ensure financial transparency with the government.",
  },
  {
    name: "GST Reg. & Returns",
    img: GST,
    desc: "GST returns are regular filings by businesses to report their sales, purchases, and tax liability for Goods and Services Tax (GST).",
  },
  {
    name: "PAN/TAN APPLICATION",
    img: CARD,
    desc: "A PAN card (Permanent Account Number) is a unique 10-digit alphanumeric identifier for tax purposes in India.",
  },
  {
    name: "TRADE MARK",
    img: TRADEMARK,
    desc: "A trademark is a unique symbol, word, or logo legally registered to protect a brand's identity and prevent imitation.",
  },
  {
    name: "FIRM REGISTRATION",
    img: REGISTERED,
    desc: "Firm registration is the process of officially establishing a business entity, ensuring legal compliance and identity in the market.",
  },
  {
    name: "TDS RETURN",
    img: TDS,
    desc: "TDS return is a concise tax filing document, summarizing tax deductions made by businesses and individuals during a specific period.",
  },
  {
    name: "FORMATION OF COMPANIES",
    img: COMPANY,
    desc: "Formation of a company involves legally establishing a new business entity, defining its structure, and registering it with authorities.",
  },
  {
    name: "DIGITAL SIGNATURE",
    img: DS,
    desc: "A digital signature is a secure, electronic fingerprint that verifies the authenticity and integrity of digital documents or messages",
  },
  {
    name: "TAX AUDIT SERVICES",
    img: ADUIT,
    desc: "A tax audit is a government examination of an individual's or business's financial records to ensure tax compliance.",
  },
  {
    name: "ISO CERTIFICATION",
    img: ISO,
    desc: "ISO certification ensures products/services meet international standards, boosting quality, reliability, and trust in businesses globally.",
  },
  {
    name: "NGO-SOCIETY-TRUST",
    img: NGO,
    desc: "Trusts help in charitable work for humankind, including medical, educational, labour, etc and An NGO usually assists the government with initiatives that are outside the scope and capabilities of the latter. However, trusts are not dependent on governmental initiatives. Because they can be either public or private.",
  },
  {
    name: "15 CA/15 CB SERVICES",
    img: FORM,
    desc: "Form 15CA is a declaration form that must be submitted electronically by the remitter to the bank before paying a non-resident. Form 15CB is a certificate issued by a Chartered Accountant (CA) to verify the deduction of taxes and the completion of other regulatory requirements.",
  },
];

export const otherServicesData = [
  "TAX Audit Services",
  "TCS Return",
  "Capital Gain Calculations",
  "School Audit Services",
  "10E Certification",
  "AIR (Annual Information Return)",
  "Companies Audit Services",
  "Projection Reports",
  "Scrutiny Assessment",
  "Partnership Audit Services",
  "CA Net Wealth Report",
  "Other Income Tax Notice",
];
