import React from "react";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import { shortFAQ } from "../data/faqData";
import Hero from "../Components/Hero";
import { Services } from "../Components/Services/";
import { servicesData } from "../data/servicesData";
import WhyUs from "../Components/WhyUs";
import FAQ from "../Components/FAQ";

const HomeScreen = () => {
  return (
      <div className="main">
      <NavBar />
        <Hero
          title={"Get Tax Assistance from No. 1 Tax Assist Company in Sirsa."}
          formTitle={"Get a FREE Consultation Now."}
          formTerms={
            '*By clicking the "Get Free Consultation" you agree to be contacted by Sharma & Associates via Phone, SMS, and/or email.'
          }
          btnText={"Get Free Consultation"}
        >
          Our expertise includes income tax assistance for individuals,
          businesses, and corporations. We assist in company formation and
          registration, along with GST registration. Our services encompass
          accounting, MIS solutions, and bookkeeping. We also provide tax
          assessment and local body tax services. With a client base spanning
          diverse sectors such as IT, manufacturing, retail, e-commerce,
          not-for-profit, education, and healthcare, we offer tailored wealth
          management and outsourced accounting solutions.
        </Hero>
        <Services data={servicesData} maxLength={50} />
        <WhyUs />
        <FAQ data={shortFAQ} />
        <Footer />
      </div>
  );
};

export default HomeScreen;
