import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "./NavBar.css";
import Button from "./Button";
import LOGO from "../assets/logo.png";
import CERTIFIED from "../assets/eri.png";
import Container from "./Container/Container";
import Section from "./Section/Section";
import { Menu, X } from "react-feather";

const NavBar = () => {
  const navigateTo = useNavigate();

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Section dark={true}>
      <nav className="nav">
        <Container className="top-bar" padding={"0.25rem 1rem"}>
          <img
            className="logo"
            src={LOGO}
            alt="logo"
            onClick={() => navigateTo("/")}
          />
          <img className="certified-logo" src={CERTIFIED} alt="" />
          <Link to="tel:+917802152552">
            <Button text="Call Us @ 78021-52552" />
          </Link>

          <div className="menu-icon" onClick={() => setMenuOpen(true)}>
            <Menu className="icon" />
          </div>
        </Container>
        <Section dark className="bottom-bar">
          <Container padding="0 1rem">
            <ul className="nav-menu">
              <li>
                <NavLink
                  to={"/"}
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/services"}
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Services
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/faq"}
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  FAQ
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/contact"}
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Contact Us
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/income-tax"}
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Income Tax
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/gst"}
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  GST
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/admin"}
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Admin
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/team"}
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Team
                </NavLink>
              </li>
            </ul>
          </Container>
        </Section>

        {menuOpen && <div className="menu-bg-overlay" onClick={()=> setMenuOpen(false)}/>}

        <div className="mobile-nav" style={{ width: menuOpen ? "250px" : "0" }}>
          <div className="close-icon" onClick={() => setMenuOpen(false)}>
            <X className="icon" />
          </div>
          <ul className="nav-menu">
            <li>
              <NavLink
                to={"/"}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/services"}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Services
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/faq"}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                FAQ
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/contact"}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Contact Us
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/income-tax"}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Income Tax
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/gst"}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                GST
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/admin"}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Admin
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/team"}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Team
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </Section>
  );
};

export default NavBar;
