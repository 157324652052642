import React from "react";
import "./Container.css";

const Container = ({ style={}, children, id="", className="", margin="", padding="" }) => {
  return (
    <div id="" className={`container ${className}`} style={{margin: margin, padding: padding,...style}}>
      {children}
    </div>
  );
};

export default Container;
