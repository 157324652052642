import React from "react";
import { Home, Mail, PhoneCall, Users } from "react-feather";
import "./Footer.css";
import CONTACT from "../assets/gourav.jpeg";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Container from "./Container/Container";

const Footer = () => {
  return (
    <Container padding="3rem 1rem 2rem">
      <div className="footer-top">
        <iframe
          className="map"
          title="map"
          src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Shop%20No.%20126,%201st%20Floor,%20behind%20LIC%20Building,%20Sirsa,%20Shamshabad%20Patti,%20Haryana%20125055,%20india+(Sharma%20&amp;%20Associates)&amp;t=k&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        ></iframe>

        <div className="footer-right">
          <img className="footer-img" src={CONTACT} alt="" />

          <p>
            You are important for us. Whether it is a simple question or a
            valuable suggestion. You can call us by phone or email us directly.
          </p>

          <div className="content-wrap">
            <div className="row">
              <div className="icon-wrap">
                <PhoneCall className="icon" />
              </div>
              <div>
                <h6>CONTACT US</h6>
                <p>+91 70821-52552</p>
              </div>
            </div>
            <div className="row">
              <div className="icon-wrap">
                <Mail className="icon" />
              </div>
              <div>
                <h6>Email US</h6>
                <p>gouravsharma994@gmail.com</p>
              </div>
            </div>
          </div>
          <div className="content-wrap">
            <div className="row">
              <div className="icon-wrap">
                <Home className="icon" />
              </div>
              <div>
                <h6> Shop No. 126, 1st Floor, behind LIC Building, 
                  Sirsa, Haryana 125055</h6>
                {/* <p className="address">
                  Shop No. 126, 1st Floor, behind LIC Building, <br />
                  Sirsa, Haryana 125055
                </p> */}
              </div>
            </div>
          </div>
          <div className="content-wrap button-wrap">
            <Link to="tel:+917802152552">
              <button className="footer-btn call">
                <PhoneCall className="icon" />
                Contact Us
              </button>
            </Link>
            <HashLink to="/#consult">
              <button className="footer-btn book">
                <Users className="icon" />
                Book Your Free Consultation
              </button>
            </HashLink>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p className="copyright">&#169; Copyright Sharma & Associates 2017</p>
      </div>
    </Container>
  );
};

export default Footer;
