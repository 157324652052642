import React from "react";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import { Services } from "../Components/Services/";
import { servicesData } from "../data/servicesData";

const ServicesScreen = () => {
  return (
    <div className="main">
      <NavBar />
      <Services data={servicesData} maxLength={50} />

      <Footer />
    </div>
  );
};

export default ServicesScreen;
