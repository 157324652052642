import React from "react";
import "./Card.css"

const Card = ({img, title, children}) => {
  return (
    <div className="card">
      <div className="top-section">
        <img src={img} alt="" />
        <h4>{title}</h4>
      </div>
      <p>
        {children}
      </p>
    </div>
  );
};

export default Card;
