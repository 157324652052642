import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import LOADING from "../assets/loading.svg";
import "./ContactForm.css";

const emailRegex = new RegExp(
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/
);
const mobileRegex = new RegExp(
  // eslint-disable-next-line no-useless-escape
  /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/
);

const ContactForm = ({ title, terms, btnText }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState("");

  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [loading, setloading] = useState(false);
  const [emailStat, setEmailStat] = useState("");

  const form = useRef();

  const checkInput = (value, setError) => {
    if (value === "") {
      setError(true);
    } else {
      setError(false);
    }
  };

  const checkRegex = (value, regex, setError) => {
    if (value === "" || !regex.test(value)) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const sendEmail = (e) => {
    setEmailStat("");
    e.preventDefault();

    if (name === "") {
      setNameError(true);
      return;
    }
    if (email === "" || !emailRegex.test(email)) {
      setEmailError(true);
      return;
    }
    if (mobile === "" || !mobileRegex.test(mobile)) {
      setMobileError(true);
      return;
    }
    if (message === "") {
      setMessageError(true);
      return;
    }
    setloading(true);

    emailjs
      .sendForm(
        "service_skd5gp9",
        "template_cgqaak7",
        form.current,
        "-gosvuIzUeCLHZcqc"
      )
      .then(
        (result) => {
          setName("");
          setEmail("");
          setMobile("");
          setMessage("");
          setloading(false);
          setEmailStat("success");
          setTimeout(()=> setEmailStat(""), 5000);
        },
        (error) => {
          setEmailStat("failed");
          console.log(error.text);
        }
      );
  };
  return (
    <form ref={form} className="form-section" onSubmit={sendEmail}>
      <h3 className="form-title">{title}</h3>
      <label htmlFor="name">Name*</label>
      <input
        className={nameError ? "error" : ""}
        id="name"
        type="text"
        name="user_name"
        value={name}
        onChange={(e) => {
          setName(e.target.value);
          checkInput(e.target.value, setNameError);
        }}
        placeholder="Enter your name."
      />
      {nameError && <p className="errorText">*Name can't be empty.</p>}
      <label htmlFor="email">Email*</label>
      <input
        className={emailError ? "error" : ""}
        id="email"
        type="text"
        value={email}
        name="user_email"
        onChange={(e) => {
          setEmail(e.target.value);
          checkRegex(e.target.value, emailRegex, setEmailError);
        }}
        placeholder="Enter your email."
      />
      {emailError && <p className="errorText">*Invalid Email Address.</p>}
      <label htmlFor="mobile">Mobile*</label>
      <input
        className={mobileError ? "error" : ""}
        id="mobile"
        name="user_mobile"
        type="text"
        value={mobile}
        onChange={(e) => {
          setMobile(e.target.value);
          checkRegex(e.target.value, mobileRegex, setMobileError);
        }}
        placeholder="Enter your mobile no."
      />
      {mobileError && <p className="errorText">*Incorrect Mobile No.</p>}
      <label htmlFor="message">Message*</label>
      <textarea
        className={messageError ? "error" : ""}
        id="message"
        type="text"
        name="message"
        value={message}
        onChange={(e) => {
          setMessage(e.target.value);
          checkInput(e.target.value, setMessageError);
        }}
        placeholder="Enter your message."
      />
      {messageError && <p className="errorText">*Message can't be empty.</p>}
      {!loading ? (
        <button type="submit">{btnText}</button>
      ) : (
        <img className="loading" src={LOADING} alt="" />
      )}
      {emailStat === "success" ? (
        <h4>
          Thanks for contacting us, our team will reach to you as soon as
          possible
        </h4>
      ) : (
        emailStat === "failed" && <h4>Error, Please try again or refresh the page.</h4>
      )}
      <h5>OR CALL 98500-90850</h5>
      <p className="terms">{terms}</p>
    </form>
  );
};

export default ContactForm;
