export const shortFAQ = [
    {
    ques: "What is Tax & GST?",
    ans: "Qui exercitation aute enim incididunt cupidatat eiusmod proident culpa reprehenderit aliqua. Occaecat ut proident dolor do laborum deserunt est sunt culpa ex consequat. Sunt Lorem nisi aute velit cupidatat anim do. Cupidatat tempor id duis esse proident et pariatur. Enim qui consequat culpa nostrud id laboris fugiat mollit quis culpa consectetur eiusmod reprehenderit est. Est cillum enim labore ea."
    },
    {
    ques: "Do you need to file tax?",
    ans: "exercitation aute enim incididunt cupidatat eiusmod proident culpa reprehenderit aliqua. Occaecat ut proident dolor do laborum deserunt est sunt culpa ex consequat. Sunt Lorem nisi aute velit cupidatat anim do. Cupidatat tempor id duis esse proident et pariatur. Enim qui consequat culpa nostrud id laboris fugiat mollit quis culpa consectetur eiusmod reprehenderit est. Est cillum enim labore ea."
    },
    {
    ques: "Documnents required for filing Tax?",
    ans: "Qui exercitation aute enim incididunt cupidatat eiusmod proident culpa reprehenderit aliqua. Occaecat ut proident dolor do laborum deserunt est sunt culpa ex consequat. Sunt Lorem nisi aute velit cupidatat anim do. Cupidatat tempor id duis esse proident et pariatur. Enim qui consequat culpa nostrud id laboris fugiat mollit quis culpa consectetur eiusmod reprehenderit est. Est cillum enim labore ea."
    },
    {
    ques: "Do I need to File a form 15 or 16?",
    ans: "Qui exercitation aute enim incididunt cupidatat eiusmod proident culpa reprehenderit aliqua. Occaecat ut proident dolor do laborum deserunt est sunt culpa ex consequat. Sunt Lorem nisi aute velit cupidatat anim do. Cupidatat tempor id duis esse proident et pariatur. Enim qui consequat culpa nostrud id laboris fugiat mollit quis culpa consectetur eiusmod reprehenderit est. Est cillum enim labore ea."
    },
    {
    ques: "How GST is applied on items?",
    ans: "Qui exercitation aute enim incididunt cupidatat eiusmod proident culpa reprehenderit aliqua. Occaecat ut proident dolor do laborum deserunt est sunt culpa ex consequat. Sunt Lorem nisi aute velit cupidatat anim do. Cupidatat tempor id duis esse proident et pariatur. Enim qui consequat culpa nostrud id laboris fugiat mollit quis culpa consectetur eiusmod reprehenderit est. Est cillum enim labore ea."
    },
    {
    ques: "Documnents required for GST?",
    ans: "Qui exercitation aute enim incididunt cupidatat eiusmod proident culpa reprehenderit aliqua. Occaecat ut proident dolor do laborum deserunt est sunt culpa ex consequat. Sunt Lorem nisi aute velit cupidatat anim do. Cupidatat tempor id duis esse proident et pariatur. Enim qui consequat culpa nostrud id laboris fugiat mollit quis culpa consectetur eiusmod reprehenderit est. Est cillum enim labore ea."
    },
]
export const longFAQ = [
    {
    ques: "What is Tax & GST?",
    ans: "Qui exercitation aute enim incididunt cupidatat eiusmod proident culpa reprehenderit aliqua. Occaecat ut proident dolor do laborum deserunt est sunt culpa ex consequat. Sunt Lorem nisi aute velit cupidatat anim do. Cupidatat tempor id duis esse proident et pariatur. Enim qui consequat culpa nostrud id laboris fugiat mollit quis culpa consectetur eiusmod reprehenderit est. Est cillum enim labore ea."
    },
    {
    ques: "Do you need to file tax?",
    ans: "exercitation aute enim incididunt cupidatat eiusmod proident culpa reprehenderit aliqua. Occaecat ut proident dolor do laborum deserunt est sunt culpa ex consequat. Sunt Lorem nisi aute velit cupidatat anim do. Cupidatat tempor id duis esse proident et pariatur. Enim qui consequat culpa nostrud id laboris fugiat mollit quis culpa consectetur eiusmod reprehenderit est. Est cillum enim labore ea."
    },
    {
    ques: "Documnents required for filing Tax?",
    ans: "Qui exercitation aute enim incididunt cupidatat eiusmod proident culpa reprehenderit aliqua. Occaecat ut proident dolor do laborum deserunt est sunt culpa ex consequat. Sunt Lorem nisi aute velit cupidatat anim do. Cupidatat tempor id duis esse proident et pariatur. Enim qui consequat culpa nostrud id laboris fugiat mollit quis culpa consectetur eiusmod reprehenderit est. Est cillum enim labore ea."
    },
    {
    ques: "Do I need to File a form 15 or 16?",
    ans: "Qui exercitation aute enim incididunt cupidatat eiusmod proident culpa reprehenderit aliqua. Occaecat ut proident dolor do laborum deserunt est sunt culpa ex consequat. Sunt Lorem nisi aute velit cupidatat anim do. Cupidatat tempor id duis esse proident et pariatur. Enim qui consequat culpa nostrud id laboris fugiat mollit quis culpa consectetur eiusmod reprehenderit est. Est cillum enim labore ea."
    },
    {
    ques: "How GST is applied on items?",
    ans: "Qui exercitation aute enim incididunt cupidatat eiusmod proident culpa reprehenderit aliqua. Occaecat ut proident dolor do laborum deserunt est sunt culpa ex consequat. Sunt Lorem nisi aute velit cupidatat anim do. Cupidatat tempor id duis esse proident et pariatur. Enim qui consequat culpa nostrud id laboris fugiat mollit quis culpa consectetur eiusmod reprehenderit est. Est cillum enim labore ea."
    },
    {
    ques: "Documnents required for GST?",
    ans: "Qui exercitation aute enim incididunt cupidatat eiusmod proident culpa reprehenderit aliqua. Occaecat ut proident dolor do laborum deserunt est sunt culpa ex consequat. Sunt Lorem nisi aute velit cupidatat anim do. Cupidatat tempor id duis esse proident et pariatur. Enim qui consequat culpa nostrud id laboris fugiat mollit quis culpa consectetur eiusmod reprehenderit est. Est cillum enim labore ea."
    },
    {
    ques: "What is Tax & GST?",
    ans: "Qui exercitation aute enim incididunt cupidatat eiusmod proident culpa reprehenderit aliqua. Occaecat ut proident dolor do laborum deserunt est sunt culpa ex consequat. Sunt Lorem nisi aute velit cupidatat anim do. Cupidatat tempor id duis esse proident et pariatur. Enim qui consequat culpa nostrud id laboris fugiat mollit quis culpa consectetur eiusmod reprehenderit est. Est cillum enim labore ea."
    },
    {
    ques: "Do you need to file tax?",
    ans: "exercitation aute enim incididunt cupidatat eiusmod proident culpa reprehenderit aliqua. Occaecat ut proident dolor do laborum deserunt est sunt culpa ex consequat. Sunt Lorem nisi aute velit cupidatat anim do. Cupidatat tempor id duis esse proident et pariatur. Enim qui consequat culpa nostrud id laboris fugiat mollit quis culpa consectetur eiusmod reprehenderit est. Est cillum enim labore ea."
    },
    {
    ques: "Documnents required for filing Tax?",
    ans: "Qui exercitation aute enim incididunt cupidatat eiusmod proident culpa reprehenderit aliqua. Occaecat ut proident dolor do laborum deserunt est sunt culpa ex consequat. Sunt Lorem nisi aute velit cupidatat anim do. Cupidatat tempor id duis esse proident et pariatur. Enim qui consequat culpa nostrud id laboris fugiat mollit quis culpa consectetur eiusmod reprehenderit est. Est cillum enim labore ea."
    },
    {
    ques: "Do I need to File a form 15 or 16?",
    ans: "Qui exercitation aute enim incididunt cupidatat eiusmod proident culpa reprehenderit aliqua. Occaecat ut proident dolor do laborum deserunt est sunt culpa ex consequat. Sunt Lorem nisi aute velit cupidatat anim do. Cupidatat tempor id duis esse proident et pariatur. Enim qui consequat culpa nostrud id laboris fugiat mollit quis culpa consectetur eiusmod reprehenderit est. Est cillum enim labore ea."
    },
    {
    ques: "How GST is applied on items?",
    ans: "Qui exercitation aute enim incididunt cupidatat eiusmod proident culpa reprehenderit aliqua. Occaecat ut proident dolor do laborum deserunt est sunt culpa ex consequat. Sunt Lorem nisi aute velit cupidatat anim do. Cupidatat tempor id duis esse proident et pariatur. Enim qui consequat culpa nostrud id laboris fugiat mollit quis culpa consectetur eiusmod reprehenderit est. Est cillum enim labore ea."
    },
    {
    ques: "Documnents required for GST?",
    ans: "Qui exercitation aute enim incididunt cupidatat eiusmod proident culpa reprehenderit aliqua. Occaecat ut proident dolor do laborum deserunt est sunt culpa ex consequat. Sunt Lorem nisi aute velit cupidatat anim do. Cupidatat tempor id duis esse proident et pariatur. Enim qui consequat culpa nostrud id laboris fugiat mollit quis culpa consectetur eiusmod reprehenderit est. Est cillum enim labore ea."
    },
    {
    ques: "What is Tax & GST?",
    ans: "Qui exercitation aute enim incididunt cupidatat eiusmod proident culpa reprehenderit aliqua. Occaecat ut proident dolor do laborum deserunt est sunt culpa ex consequat. Sunt Lorem nisi aute velit cupidatat anim do. Cupidatat tempor id duis esse proident et pariatur. Enim qui consequat culpa nostrud id laboris fugiat mollit quis culpa consectetur eiusmod reprehenderit est. Est cillum enim labore ea."
    },
    {
    ques: "Do you need to file tax?",
    ans: "exercitation aute enim incididunt cupidatat eiusmod proident culpa reprehenderit aliqua. Occaecat ut proident dolor do laborum deserunt est sunt culpa ex consequat. Sunt Lorem nisi aute velit cupidatat anim do. Cupidatat tempor id duis esse proident et pariatur. Enim qui consequat culpa nostrud id laboris fugiat mollit quis culpa consectetur eiusmod reprehenderit est. Est cillum enim labore ea."
    },
    {
    ques: "Documnents required for filig Tax?",
    ans: "Qui exercitation aute enim incididunt cupidatat eiusmod proident culpa reprehenderit aliqua. Occaecat ut proident dolor do laborum deserunt est sunt culpa ex consequat. Sunt Lorem nisi aute velit cupidatat anim do. Cupidatat tempor id duis esse proident et pariatur. Enim qui consequat culpa nostrud id laboris fugiat mollit quis culpa consectetur eiusmod reprehenderit est. Est cillum enim labore ea."
    },
    {
    ques: "Do I need to File a form 15 or 16?",
    ans: "Qui exercitation aute enim incididunt cupidatat eiusmod proident culpa reprehenderit aliqua. Occaecat ut proident dolor do laborum deserunt est sunt culpa ex consequat. Sunt Lorem nisi aute velit cupidatat anim do. Cupidatat tempor id duis esse proident et pariatur. Enim qui consequat culpa nostrud id laboris fugiat mollit quis culpa consectetur eiusmod reprehenderit est. Est cillum enim labore ea."
    },
    {
    ques: "How GST is applied on items?",
    ans: "Qui exercitation aute enim incididunt cupidatat eiusmod proident culpa reprehenderit aliqua. Occaecat ut proident dolor do laborum deserunt est sunt culpa ex consequat. Sunt Lorem nisi aute velit cupidatat anim do. Cupidatat tempor id duis esse proident et pariatur. Enim qui consequat culpa nostrud id laboris fugiat mollit quis culpa consectetur eiusmod reprehenderit est. Est cillum enim labore ea."
    },
    {
    ques: "Documnents required for GST?",
    ans: "Qui exercitation aute enim incididunt cupidatat eiusmod proident culpa reprehenderit aliqua. Occaecat ut proident dolor do laborum deserunt est sunt culpa ex consequat. Sunt Lorem nisi aute velit cupidatat anim do. Cupidatat tempor id duis esse proident et pariatur. Enim qui consequat culpa nostrud id laboris fugiat mollit quis culpa consectetur eiusmod reprehenderit est. Est cillum enim labore ea."
    },
]