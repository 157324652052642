import React from "react";
import Container from "./Container/Container";
import "./WhyUs.css";
import GST from "../assets/gst2.webp";
import TAX from "../assets/tax.webp";
import ISO from "../assets/iso.webp";
import WHYUS from "../assets/Why-us.webp";
import INCREASEMONEY from "../assets/increase-money.webp";
import SAVEMONEY from "../assets/save-money.webp";

const WhyUs = () => {
  return (
    <Container padding="1rem 1rem 3rem">
      <h2 className="title">Why we are Best?</h2>
      <div className="whyUs-wrapper">
        <img className="img" src={WHYUS} alt="" />

        <div>
          <p className="sub-text">
            We was formulated in the early 2017 with the vision of offering all
            types of online taxation & Financial Services. The company has been
            promoted by experience Taxation & Financial consultants for more
            than 10 years. Our work so far has been in providing hassle free
            services to our clients. The company has worked on online services
            to assist their clients in managing various return hassle free &
            timely. In a short span of time the company already has a
            respectable clientele to its credit.
          </p>

          <div className="items-wrapper">
            <div className="items">
              <img className="icon" src={GST} alt="" />
              <h6>01</h6>
              <p>Efficient GST Solutions</p>
            </div>
            <div className="items">
              <img className="icon" src={SAVEMONEY} alt="" />
              <h6>02</h6>
              <p>Tax Optimization Specialists</p>
            </div>
            <div className="items">
              <img className="icon" src={INCREASEMONEY} alt="" />
              <h6>03</h6>
              <p>Strategic Business Growth</p>
            </div>
            <div className="items">
              <img className="icon" src={TAX} alt="" />
              <h6>04</h6>
              <p>Precise Income Tax Solutions</p>
            </div>
            <div className="items">
              <img className="icon" src={ISO} alt="" />
              <h6>05</h6>
              <p>ISO Certification Experts</p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default WhyUs;
